import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import TermsPage from "../components/TermsPage";
import PrivacyPage from "../components/PrivacyPage";
import ContactPage from "../components/ContactPage";
import NotFoundPage from '../components/common/NotFoundPage';

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default MainRoutes;