import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MenuItem, Select, Button, FormControl, InputLabel, Snackbar, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function EditUser() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [grade, setGrade] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/user/edit/${id}`);
        setUser(response.data);
        setGrade(response.data.grade);
        setLoading(false);
      } catch (err) {
        setError('사용자 정보를 불러오는 중 오류가 발생했습니다.');
        setLoading(false);
      }
    };

    fetchUser();
  }, [API_URL, id]);

  const handleGradeChange = (event) => {
    setGrade(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('access_token');
      await axios.put(
        `${API_URL}/api/user/edit/${id}`,
        { grade },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSnackbarMessage('회원 등급이 성공적으로 변경되었습니다.');
      setSnackbarOpen(true);
      navigate('/user/list');
    } catch (err) {
      setSnackbarMessage('회원 등급을 변경하는 중 오류가 발생했습니다.');
      setSnackbarOpen(true);
      console.error(err);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: '20px' }}>
      <Helmet>
        <title>{user?.username} 회원 수정</title>
        <meta name="description" content={`회원 ${user?.username}의 등급을 수정합니다.`} />
      </Helmet>

      <h1>{user.username} 회원 수정</h1>
      <FormControl fullWidth style={{ marginBottom: '20px' }}>
        <InputLabel id="grade-select-label">회원 등급</InputLabel>
        <Select
          labelId="grade-select-label"
          id="grade-select"
          value={grade}
          label="회원 등급"
          onChange={handleGradeChange}
        >
          <MenuItem value="basic">일반 사용자 (Basic)</MenuItem>
          <MenuItem value="premium">프리미엄 사용자 (Premium)</MenuItem>
          <MenuItem value="admin">관리자 (Admin)</MenuItem>
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        회원 등급 수정
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
}
