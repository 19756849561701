import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
} from '@mui/material';

function SearchKeyword() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSort, setSearchSort] = useState('sim'); // 기본값은 '정확도순'
  const [results, setResults] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setSearchInitiated(false);
    setErrorMessage(null);
    setResults([]);

    // JSON 형식으로 요청 데이터 생성
    const requestData = {
      search_query: searchQuery,
      search_sort: searchSort,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/smartstore/search-keyword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('키워드 검색 중 오류가 발생했습니다.');
      }

      const data = await response.json();
      setResults(data.results || []);
      setSearchInitiated(true);
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('키워드 검색 요청 중 오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>스마트스토어 키워드로 제품 검색</title>
        <meta name="description" content="스마트스토어에서 키워드로 제품을 검색하여 정확도 및 가격순으로 정렬합니다." />
      </Helmet>

      <Box py={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          스마트스토어 키워드로 제품 검색
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={3}>
        <TextField
          label="키워드"
          variant="outlined"
          fullWidth
          required
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <FormControl fullWidth>
          <InputLabel>검색 기준</InputLabel>
          <Select
            value={searchSort}
            onChange={(e) => setSearchSort(e.target.value)}
            label="검색 기준"
          >
            <MenuItem value="sim">정확도순</MenuItem>
            <MenuItem value="dsc">가격 높은순</MenuItem>
            <MenuItem value="asc">가격 낮은순</MenuItem>
          </Select>
        </FormControl>

        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : '랭킹 확인'}
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage}
        </Alert>
      )}

      {searchInitiated && (
        <Box mt={4}>
          {results.length > 0 ? (
            <List>
              {results.map((item, index) => (
                <ListItem
                  key={index}
                  button
                  component="a"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText
                    primary={`${index + 1}. ${item.title}`}
                    secondary={`${item.mallName} / ${item.lprice}원`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="textSecondary">검색 결과가 없습니다.</Typography>
          )}
        </Box>
      )}
    </Container>
  );
}

export default SearchKeyword;
