import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  Snackbar,
} from '@mui/material';
import { Helmet } from 'react-helmet';

export default function UserLogin() {
  const [username, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ detail: [] });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { setIsLogin, setUsername, setAccessToken, setUserGrade } = useContext(AuthContext);

  const API_URL = process.env.REACT_APP_API_URL;

  const loginUser = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      const errorDetails = [];
      if (!username) errorDetails.push("아이디를 입력해주세요.");
      if (!password) errorDetails.push("비밀번호를 입력해주세요.");
      setError({ detail: errorDetails });
      setSnackbarOpen(true); // Snackbar 표시
      return;
    }

    try {
      const url = `${API_URL}/api/user/login`;
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);

      const response = await axios.post(url, params);
      const { access_token, username: responseUsername, grade: responseUserGrade } = response.data;

      setIsLogin(true);
      setUsername(responseUsername);
      setAccessToken(access_token);
      setUserGrade(responseUserGrade);

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('username', responseUsername);
      localStorage.setItem('grade', responseUserGrade);

      navigate('/');
    } catch (err) {
      const responseDetail = err.response?.data?.detail;
      if (Array.isArray(responseDetail)) {
        setError({ detail: responseDetail });
      } else if (responseDetail && typeof responseDetail === 'object') {
        setError({ detail: [responseDetail.msg || '알 수 없는 오류가 발생했습니다.'] });
      } else {
        setError({ detail: ["아이디 또는 비밀번호를 확인하세요"] });
      }
      setSnackbarOpen(true); // Snackbar 표시
      console.error("Error:", err.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>로그인 - 사이트 이름</title>
      </Helmet>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: 1,
          padding: 4,
        }}
      >
        <Typography component="h1" variant="h5">
          로그인
        </Typography>
        <Box component="form" onSubmit={loginUser} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label="아이디"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsernameInput(e.target.value)}
            sx={{ mb: 0 }}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="비밀번호"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 0 }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="아이디 저장"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            로그인
          </Button>
          <Button
            component={Link}
            to="/user/create"
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
          >
            회원가입
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={error.detail.join(' ')}
      />
    </Container>
  );
}
