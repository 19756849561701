import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Button, TextField, Alert, CircularProgress } from '@mui/material';

function OfficePdfSplit() {
  const [pdfFile, setPdfFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const handleFileChange = (event) => {
    setPdfFile(event.target.files[0]);
    setErrorMessage(null); // 새로운 파일 선택 시 에러 메시지 초기화
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!pdfFile) {
      setErrorMessage('파일을 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('pdf_file', pdfFile);

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/tool/office/pdf-split`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('PDF 분할 중 오류가 발생했습니다.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'split_pdf.zip'; // ZIP 파일로 다운로드
      document.body.appendChild(a);
      a.click();
      a.remove();

      setPdfFile(null); // 파일 선택 초기화
      setErrorMessage(null); // 에러 메시지 초기화
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>PDF 분할 - 모두의 도구</title>
        <meta name="description" content="PDF 파일을 페이지별로 분할하여 ZIP 파일로 다운로드할 수 있는 도구입니다." />
        <meta property="og:title" content="PDF 분할 - 모두의 도구" />
        <meta property="og:description" content="PDF 파일을 업로드하여 페이지별로 분할해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        PDF 분할
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          type="file"
          fullWidth
          inputProps={{ accept: 'application/pdf' }}
          onChange={handleFileChange}
          variant="outlined"
          required
          sx={{ mb: 3 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? <CircularProgress size={24} /> : '분할 및 다운로드'}
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
}

export default OfficePdfSplit;
