import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserCreate from '../components/users/UserCreate';
import UserLogin from '../components/users/UserLogin';
import UserPage from '../components/users/UserPage';
import UserList from '../components/users/UserList';
import EditUser from '../components/users/EditUser'; 
import NotFoundPage from '../components/common/NotFoundPage';

function UserRoutes() {
  return (
    <Routes>
      <Route path="create" element={<UserCreate />} />
      <Route path="login" element={<UserLogin />} />
      <Route path="mypage" element={<UserPage />} /> 
      <Route path="list" element={<UserList />} /> 
      <Route path="edit/:id" element={<EditUser />} /> 
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default UserRoutes;
