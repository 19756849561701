import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';

function DebtRepaymentCalculator() {
  const [debts, setDebts] = useState([{ principal: '', annualInterestRate: '', repaymentTermMonths: '' }]);
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleDebtChange = (index, field, value) => {
    const updatedDebts = debts.map((debt, i) => 
      i === index ? { ...debt, [field]: value } : debt
    );
    setDebts(updatedDebts);
  };

  const addDebtField = () => {
    setDebts([...debts, { principal: '', annualInterestRate: '', repaymentTermMonths: '' }]);
  };

  const removeDebtField = (index) => {
    const updatedDebts = debts.filter((_, i) => i !== index);
    setDebts(updatedDebts);
  };

  const calculateDebtRepayment = async () => {
    const requestData = {
      debts: debts.map(debt => ({
        principal: parseFloat(debt.principal),
        annual_interest_rate: parseFloat(debt.annualInterestRate),
        repayment_term_months: parseInt(debt.repaymentTermMonths, 10),
      })),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/debt-repayment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('부채 상환 계획 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>부채 상환 계획 계산기 - 모두의 도구</title>
        <meta name="description" content="여러 부채의 원금, 이자, 상환 기간을 입력하여 총 상환액과 월 상환액을 계산하는 부채 상환 계획 계산기입니다." />
        <meta property="og:title" content="부채 상환 계획 계산기 - 모두의 도구" />
        <meta property="og:description" content="부채 상환 계획 계산기를 통해 다양한 부채의 월별 상환액과 총 상환액을 손쉽게 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        부채 상환 계획 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        {debts.map((debt, index) => (
          <Box key={index} sx={{ mb: 2, border: '1px solid #ccc', p: 2, borderRadius: 1 }}>
            <Typography variant="h6">부채 {index + 1}</Typography>
            <TextField
              fullWidth
              label="원금 (원)"
              variant="outlined"
              value={debt.principal}
              onChange={(e) => handleDebtChange(index, 'principal', e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="연이자율 (%)"
              variant="outlined"
              value={debt.annualInterestRate}
              onChange={(e) => handleDebtChange(index, 'annualInterestRate', e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="상환 기간 (개월)"
              variant="outlined"
              value={debt.repaymentTermMonths}
              onChange={(e) => handleDebtChange(index, 'repaymentTermMonths', e.target.value)}
              sx={{ mb: 2 }}
            />
            <IconButton color="primary" onClick={addDebtField}>
              <AddCircle />
            </IconButton>
            {debts.length > 1 && (
              <IconButton color="secondary" onClick={() => removeDebtField(index)}>
                <RemoveCircle />
              </IconButton>
            )}
          </Box>
        ))}
        <Button variant="contained" color="primary" onClick={calculateDebtRepayment}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="총 월 상환액" secondary={`${result.total_monthly_payment.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 상환액" secondary={`${result.total_repayment_amount.toLocaleString()} 원`} />
            </ListItem>
            <Typography variant="h6" sx={{ mt: 2 }}>부채별 상환 세부 정보</Typography>
            {result.monthly_repayments.map((repayment, index) => (
              <List key={index} sx={{ mb: 2, borderBottom: '1px solid #ccc', pb: 2 }}>
                <ListItem>
                  <ListItemText primary={`부채 ${index + 1} - 원금`} secondary={`${repayment.principal.toLocaleString()} 원`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="월 상환액" secondary={`${repayment.monthly_payment.toLocaleString()} 원`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="총 상환액" secondary={`${repayment.total_payment.toLocaleString()} 원`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="총 이자" secondary={`${repayment.interest_paid.toLocaleString()} 원`} />
                </ListItem>
              </List>
            ))}
          </List>
        </Box>
      )}
    </Container>
  );
}

export default DebtRepaymentCalculator;
