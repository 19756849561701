import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function DepositCalculator() {
  const [annualInterestRate, setAnnualInterestRate] = useState('');
  const [principal, setPrincipal] = useState('');
  const [monthlyDeposit, setMonthlyDeposit] = useState('');
  const [periodMonths, setPeriodMonths] = useState('');
  const [targetAmount, setTargetAmount] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateDeposit = async () => {
    const requestData = {
      annual_interest_rate: parseFloat(annualInterestRate),
      principal: parseFloat(principal),
      monthly_deposit: parseFloat(monthlyDeposit),
      period_months: parseInt(periodMonths, 10),
      target_amount: parseFloat(targetAmount),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/deposit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('적금/예금 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>적금/예금 이자 및 목표 금액 계산기 - 모두의 도구</title>
        <meta name="description" content="예금, 적금의 이자와 목표 금액을 간편하게 계산해주는 계산기입니다. 목표 금액 달성에 필요한 월 적립금도 확인해보세요." />
        <meta property="og:title" content="적금/예금 이자 및 목표 금액 계산기 - 모두의 도구" />
        <meta property="og:description" content="예금과 적금의 이자와 목표 금액을 계산하고 목표 달성을 위한 월 적립금을 확인할 수 있습니다." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        적금/예금 이자 및 목표 금액 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="연이자율 (%)"
          variant="outlined"
          value={annualInterestRate}
          onChange={(e) => setAnnualInterestRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="원금 (원)"
          variant="outlined"
          value={principal}
          onChange={(e) => setPrincipal(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="월 적립금 (원)"
          variant="outlined"
          value={monthlyDeposit}
          onChange={(e) => setMonthlyDeposit(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="적립 기간 (개월)"
          variant="outlined"
          value={periodMonths}
          onChange={(e) => setPeriodMonths(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="목표 금액 (원)"
          variant="outlined"
          value={targetAmount}
          onChange={(e) => setTargetAmount(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateDeposit}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            {result.monthly_deposit !== undefined && (
              <ListItem>
                <ListItemText primary="필요한 월 적립금" secondary={`${result.monthly_deposit.toLocaleString()} 원`} />
              </ListItem>
            )}
            {result.final_amount !== undefined && (
              <ListItem>
                <ListItemText primary="최종 금액" secondary={`${result.final_amount.toLocaleString()} 원`} />
              </ListItem>
            )}
            {result.total_interest !== undefined && (
              <ListItem>
                <ListItemText primary="총 이자" secondary={`${result.total_interest.toLocaleString()} 원`} />
              </ListItem>
            )}
            {result.target_amount && (
              <ListItem>
                <ListItemText primary="목표 금액" secondary={`${result.target_amount.toLocaleString()} 원`} />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary="연이자율" secondary={`${result.annual_interest_rate} %`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default DepositCalculator;
