import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Checkbox, FormControlLabel, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function IncomeTaxCalculator() {
  const [income, setIncome] = useState('');
  const [isHousehold, setIsHousehold] = useState(false);
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateIncomeTax = async () => {
    const requestData = {
      income: parseFloat(income),
      is_household: isHousehold,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/income-tax`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('소득세 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>소득세 계산기 - 모두의 도구</title>
        <meta name="description" content="개인 및 가구 소득에 따른 소득세와 지방세를 계산하는 소득세 계산기입니다." />
        <meta property="og:title" content="소득세 계산기 - 모두의 도구" />
        <meta property="og:description" content="소득 금액을 입력하고 가구 소득 여부를 선택하여 예상되는 소득세와 총 세액을 확인하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        소득세 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="소득 금액 (원)"
          variant="outlined"
          value={income}
          onChange={(e) => setIncome(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={<Checkbox checked={isHousehold} onChange={(e) => setIsHousehold(e.target.checked)} />}
          label="가구 소득 여부"
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateIncomeTax}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="소득 금액" secondary={`${result.income.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="가구 소득 여부" secondary={result.is_household ? '예' : '아니오'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="소득세" secondary={`${result.income_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="지방세" secondary={`${result.local_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 세액" secondary={`${result.total_tax.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default IncomeTaxCalculator;
