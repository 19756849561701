import React from "react";
import { Container, Typography, Box, Paper, Divider } from "@mui/material";

function PrivacyPage() {
  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          개인정보처리방침
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 2 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              1. 수집하는 개인정보의 항목
            </Typography>
            <Typography variant="body1" component="div" paragraph>
              모두의도구는 서비스 제공을 위해 다음과 같은 개인정보를 수집합니다:
              <ul style={{ marginLeft: "20px" }}>
                <li>이름, 이메일 주소, 연락처</li>
                <li>서비스 이용 기록, 접속 로그, 쿠키</li>
              </ul>
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              2. 개인정보의 수집 및 이용 목적
            </Typography>
            <Typography variant="body1" component="div" paragraph>
              모두의도구는 수집한 개인정보를 다음과 같은 목적으로 이용합니다:
              <ul style={{ marginLeft: "20px" }}>
                <li>서비스 제공 및 운영</li>
                <li>고객 상담 및 민원 처리</li>
                <li>이벤트 당첨시 연락</li>
              </ul>
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* 다른 항목을 여기에 추가 */}
        </Box>
      </Paper>
    </Container>
  );
}

export default PrivacyPage;
