import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function YearEndTaxCalculator() {
  const [income, setIncome] = useState('');
  const [dependents, setDependents] = useState('');
  const [creditCardExpense, setCreditCardExpense] = useState('');
  const [donation, setDonation] = useState('');
  const [insurancePremium, setInsurancePremium] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateYearEndTax = async () => {
    const requestData = {
      income: parseFloat(income),
      dependents: parseInt(dependents, 10),
      credit_card_expense: parseFloat(creditCardExpense),
      donation: parseFloat(donation),
      insurance_premium: parseFloat(insurancePremium),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/year-end-tax`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('연말정산 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>연말정산 계산기 - 모두의 도구</title>
        <meta name="description" content="소득 금액과 공제 항목을 입력하여 연말정산 결과를 계산하는 연말정산 계산기입니다." />
        <meta property="og:title" content="연말정산 계산기 - 모두의 도구" />
        <meta property="og:description" content="소득, 공제 항목을 입력하여 예상 환급액과 연말정산 결과를 확인해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        연말정산 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="소득 금액 (원)"
          variant="outlined"
          value={income}
          onChange={(e) => setIncome(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="부양가족 수"
          variant="outlined"
          value={dependents}
          onChange={(e) => setDependents(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="신용카드 사용 금액 (원)"
          variant="outlined"
          value={creditCardExpense}
          onChange={(e) => setCreditCardExpense(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="기부금 (원)"
          variant="outlined"
          value={donation}
          onChange={(e) => setDonation(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="보험료 (원)"
          variant="outlined"
          value={insurancePremium}
          onChange={(e) => setInsurancePremium(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateYearEndTax}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="기본 공제" secondary={`${result.basic_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="신용카드 공제" secondary={`${result.credit_card_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="기부금 공제" secondary={`${result.donation_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="보험료 공제" secondary={`${result.insurance_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 공제액" secondary={`${result.total_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="공제 전 세금" secondary={`${result.tax_before_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="공제 후 세금" secondary={`${result.tax_after_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="예상 환급액" secondary={`${result.refund_estimate.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default YearEndTaxCalculator;
