import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText, MenuItem, Select } from "@mui/material";

function NetSalaryCalculator() {
  const [annualSalary, setAnnualSalary] = useState("");
  const [nonTaxableAmount, setNonTaxableAmount] = useState("2400000"); // 기본값 240만 원
  const [dependents, setDependents] = useState(0); // 기본값 0명
  const [childrenUnder20, setChildrenUnder20] = useState(0); // 기본값 0명
  const [result, setResult] = useState(null);
  const [error, setError] = useState(""); // 에러 메시지 상태 추가
  const API_URL = process.env.REACT_APP_API_URL;

  const formatNumber = (value) => {
    if (!value) return "";
    return new Intl.NumberFormat().format(value);
  };

  const handleAnnualSalaryChange = (e) => {
    const value = e.target.value.replace(/,/g, ""); // 입력 값에서 ',' 제거
    if (!isNaN(value)) {
      setAnnualSalary(formatNumber(value));
      setError(""); // 입력 시 에러 메시지 제거
    }
  };

  const calculateDeductions = async () => {
    if (!annualSalary) {
      setError("연봉을 입력해주세요."); // 연봉 입력 유효성 검사
      return;
    }

    const requestData = {
      annual_salary: parseFloat(annualSalary.replace(/,/g, "")), // ',' 제거 후 숫자로 변환
      non_taxable_amount: parseFloat(nonTaxableAmount.replace(/,/g, "")) || 0,
      dependents: dependents || 0,
      children_under_20: childrenUnder20 || 0,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/salary`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error("Calculation failed");
      }

      const data = await response.json();
      setResult({
        gross_salary: Math.round(data.gross_salary),
        tax_deduction: Math.round(data.tax_deduction),
        pension_deduction: Math.round(data.pension_deduction),
        health_insurance_deduction: Math.round(data.health_insurance_deduction),
        total_deduction: Math.round(data.total_deduction),
        net_salary: Math.round(data.net_salary),
      });
      setError(""); // 성공적으로 계산된 경우 에러 메시지 제거
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>연봉 계산기 - 모두의 도구</title>
        <meta name="description" content="연봉, 비과세액, 부양가족 수, 자녀 수를 입력하여 공제액과 실수령 연봉을 계산합니다." />
        <meta property="og:title" content="연봉 계산기 - 모두의 도구" />
        <meta property="og:description" content="공제액을 반영한 예상 실수령 연봉을 확인하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        연봉 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="연봉 (원)"
          variant="outlined"
          value={annualSalary}
          onChange={handleAnnualSalaryChange}
          error={!!error} // 에러 상태 적용
          helperText={error} // 에러 메시지 표시
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="비과세액 (원)"
          variant="outlined"
          value={formatNumber(nonTaxableAmount)}
          onChange={(e) => setNonTaxableAmount(e.target.value.replace(/,/g, ""))}
          sx={{ mb: 1 }}
        />
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          식대는 월 20만원까지 비과세입니다. 그 외에 비과세 항목이 있으면 금액을 수정해주세요.
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography>부양가족 수 (명)</Typography>
          <Select
            fullWidth
            value={dependents}
            onChange={(e) => setDependents(e.target.value)}
            variant="outlined"
          >
            {[0, 1, 2, 3, 4, "5명 이상"].map((num) => (
              <MenuItem key={num} value={num === "5명 이상" ? 5 : num}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography>20세 이하 자녀 수 (명)</Typography>
          <Select
            fullWidth
            value={childrenUnder20}
            onChange={(e) => setChildrenUnder20(e.target.value)}
            variant="outlined"
          >
            {[0, 1, 2, "3명 이상"].map((num) => (
              <MenuItem key={num} value={num === "3명 이상" ? 3 : num}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button variant="contained" color="primary" onClick={calculateDeductions}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="총 연봉" secondary={`${formatNumber(result.gross_salary)} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="세금 공제" secondary={`${formatNumber(result.tax_deduction)} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="국민연금 공제" secondary={`${formatNumber(result.pension_deduction)} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="건강보험 공제" secondary={`${formatNumber(result.health_insurance_deduction)} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 공제액" secondary={`${formatNumber(result.total_deduction)} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="세후 연봉" secondary={`${formatNumber(result.net_salary)} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default NetSalaryCalculator;
