import React, { useState } from 'react';
import { Grid, Paper, Typography, Box, Container, TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import toolsData from './toolsData';

export default function ToolMain() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleSearch = () => {
    const results = toolsData
      .flatMap(category => category.tools)
      .filter(tool =>
        tool.title.includes(searchTerm) || tool.description.includes(searchTerm)
      );
    setSearchResults(results);
    setSearchPerformed(true);
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 5 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          label="도구 검색"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          검색
        </Button>
      </Box>

      {searchPerformed && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Typography variant="h5">검색 결과</Typography>
        </Box>
      )}

      {searchPerformed ? (
        searchResults.length > 0 ? (
          <Grid container spacing={4}>
            {searchResults.map((tool, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <Link to={tool.link} style={{ textDecoration: 'none', flexGrow: 1 }}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 3,
                      backgroundColor: 'white',
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%',
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        backgroundColor: '#e8eaf6',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Box sx={{ mr: 2 }}>{tool.icon}</Box>
                      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
                        {tool.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {tool.description}
                    </Typography>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" color="textSecondary">
            검색결과가 없습니다
          </Typography>
        )
      ) : (
        toolsData.map((category, index) => (
          <Box key={index} sx={{ mb: 5 }}>
            <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
              {category.category}
            </Typography>
            <Grid container spacing={4}>
              {category.tools.map((tool, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx} sx={{ display: 'flex' }}>
                  <Link to={tool.link} style={{ textDecoration: 'none', flexGrow: 1 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 3,
                        backgroundColor: 'white',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        transition: 'transform 0.3s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          backgroundColor: '#e8eaf6',
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ mr: 2 }}>{tool.icon}</Box>
                        <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
                          {tool.title}
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {tool.description}
                      </Typography>
                    </Paper>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))
      )}
    </Container>
  );
}
