import React from 'react';
import { Box, Typography } from '@mui/material';


function Footer() {
  return (
    <Box component="footer" sx={{ py: 3, mt: 4, borderTop: '1px solid #e0e0e0', backgroundColor: '#f8f9fa' }}>
      <Box sx={{ py: 3, textAlign: 'center', bgcolor: 'primary.main', color: '#fff' }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          © 2024 BrewChips All rights reserved.
        </Typography>
        <Typography variant="body2">
          <a href="/terms" style={{ color: '#fff', textDecoration: 'none' }}>이용약관</a> | <a href="/privacy" style={{ color: '#fff', textDecoration: 'none' }}>개인정보처리방침</a> | <a href="/contact" style={{ color: '#fff', textDecoration: 'none' }}>고객센터</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
