import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const API_URL = process.env.REACT_APP_API_URL;

function ExcelTools() {
  const [tool, setTool] = useState("extract-sheet");
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [sheetName, setSheetName] = useState("");
  const [columnName, setColumnName] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");

  const handleFileChange = (e) => setFile(e.target.files[0]);
  const handleFilesChange = (e) => setFiles([...e.target.files]);

  const handleSubmit = async () => {
    setLoading(true);
    setDownloadLink("");

    const formData = new FormData();
    if (tool === "merge") {
      files.forEach((file) => formData.append("files", file));
    } else {
      formData.append("file", file);
    }

    if (tool === "extract-sheet") formData.append("sheet_name", sheetName);
    if (tool === "remove-duplicates") formData.append("column", columnName);

    try {
      const response = await fetch(`${API_URL}/api/tool/office/excel/${tool}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("작업 실패");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setDownloadLink(url);
    } catch (error) {
      console.error("Error:", error);
      alert("작업 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        엑셀 도구
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>도구 선택</InputLabel>
        <Select
          label="도구 선택"
          value={tool}
          onChange={(e) => setTool(e.target.value)}
        >
          <MenuItem value="extract-sheet">엑셀 시트 추출기</MenuItem>
          <MenuItem value="merge">엑셀 병합기</MenuItem>
          <MenuItem value="split">엑셀 분할기</MenuItem>
          <MenuItem value="remove-duplicates">엑셀 중복 데이터 제거</MenuItem>
        </Select>
      </FormControl>

      {tool === "merge" ? (
        <Box>
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            파일 선택 (여러 개)
            <input type="file" multiple hidden onChange={handleFilesChange} />
          </Button>
          <List>
            {files.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <Box>
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            파일 선택
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {file && (
            <Typography variant="body2" sx={{ mb: 2 }}>
              선택된 파일: {file.name}
            </Typography>
          )}
        </Box>
      )}

      {tool === "extract-sheet" && (
        <TextField
          fullWidth
          label="추출할 시트 이름"
          value={sheetName}
          onChange={(e) => setSheetName(e.target.value)}
          sx={{ mb: 2 }}
        />
      )}

      {tool === "remove-duplicates" && (
        <TextField
          fullWidth
          label="중복 제거 기준 열 이름"
          value={columnName}
          onChange={(e) => setColumnName(e.target.value)}
          sx={{ mb: 2 }}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "작업 시작"}
      </Button>

      {downloadLink && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            href={downloadLink}
            download={
              tool === "extract-sheet"
                ? "extracted_sheet.xlsx"
                : tool === "merge"
                ? "merged_excel.xlsx"
                : tool === "split"
                ? "split_excel.zip"
                : tool === "remove-duplicates"
                ? "deduplicated_excel.xlsx"
                : "result.zip"
            }
          >
            결과 다운로드
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default ExcelTools;
