import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function EducationCostCalculator() {
  const [tuitionFee, setTuitionFee] = useState('');
  const [otherExpenses, setOtherExpenses] = useState('');
  const [educationPeriodYears, setEducationPeriodYears] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateEducationCost = async () => {
    const requestData = {
      tuition_fee: parseFloat(tuitionFee),
      other_expenses: parseFloat(otherExpenses),
      education_period_years: parseInt(educationPeriodYears, 10),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/education-cost`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('교육비 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>교육비 계산기 - 모두의 도구</title>
        <meta name="description" content="학비와 기타 비용을 포함한 교육비를 계산하여 필요한 총 교육비와 월별 예산을 예측하는 계산기입니다." />
        <meta property="og:title" content="교육비 계산기 - 모두의 도구" />
        <meta property="og:description" content="학비와 기타 교육 비용을 포함한 총 교육비와 월별 예산을 손쉽게 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        교육비 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="학비 (원)"
          variant="outlined"
          value={tuitionFee}
          onChange={(e) => setTuitionFee(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="기타 비용 (원)"
          variant="outlined"
          value={otherExpenses}
          onChange={(e) => setOtherExpenses(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="교육 기간 (년)"
          variant="outlined"
          value={educationPeriodYears}
          onChange={(e) => setEducationPeriodYears(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateEducationCost}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="학비" secondary={`${result.tuition_fee.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="기타 비용" secondary={`${result.other_expenses.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="교육 기간" secondary={`${result.education_period_years} 년`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 교육비" secondary={`${result.total_cost.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="월별 예산" secondary={`${result.monthly_budget.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default EducationCostCalculator;
