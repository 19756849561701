import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [userGrade, setUserGrade] = useState('');  

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const user = localStorage.getItem('username');
    const grade = localStorage.getItem('grade');  
    if (token && user && grade) {
      setIsLogin(true);
      setUsername(user);
      setAccessToken(token);
      setUserGrade(grade);  
    }
  }, [setUserGrade]);
  console.log('Current user grade:', userGrade);
  return (
    <AuthContext.Provider value={{ isLogin, setIsLogin, username, setUsername, accessToken, setAccessToken, userGrade, setUserGrade }}>
      {children}
    </AuthContext.Provider>
  );
};
