import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Card, CardContent, Grid, TextField, Typography, Snackbar } from '@mui/material';
import { Helmet } from 'react-helmet';

function UserCreate() {
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({ detail: [] });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  const postUser = (event) => {
    event.preventDefault();

    if (!username || username.length < 4) {
      setError({ detail: ["아이디는 4자리 이상이어야 합니다."] });
      return;
    }

    if (password1 !== password2) {
      setError({ detail: ["비밀번호가 일치하지 않습니다."] });
      return;
    }

    const url = `${API_URL}/api/user/create`;
    const params = {
      username: username,
      password1: password1,
      password2: password2,
      email: email,
    };

    axios
      .post(url, params)
      .then(() => {
        setSnackbarOpen(true);
        navigate('/user/login')
      })
      .catch((err) => {
        if (err.response) {
          const errorDetails = Array.isArray(err.response.data.detail)
            ? err.response.data.detail.map((detail) => detail.msg || '오류가 발생했습니다.')
            : [err.response.data.detail || '오류가 발생했습니다.'];
          setError({ detail: errorDetails });
        } else {
          setError({ detail: ["오류가 발생했습니다. 다시 시도해 주세요."] });
          console.error("Error:", err.message);
        }
      });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Helmet>
        <title>회원 가입 - 사이트 이름</title>
        <meta name="description" content="회원 가입을 통해 다양한 서비스를 이용하세요." />
      </Helmet>
      <Card variant="outlined" sx={{ width: '400px', padding: 2 }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            회원 가입
          </Typography>
          <form onSubmit={postUser}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="아이디"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="비밀번호"
                  type="password"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="비밀번호 확인"
                  type="password"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="이메일"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            {error.detail.length > 0 && (
              <Box mt={2}>
                {error.detail.map((msg, index) => (
                  <Typography key={index} color="error" variant="body2">
                    {msg}
                  </Typography>
                ))}
              </Box>
            )}
            <Box mt={2}>
              <Button type="submit" variant="contained" fullWidth>
                회원가입
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="회원 가입이 완료되었습니다!"
      />
    </Box>
  );
}

export default UserCreate;
