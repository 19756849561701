import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Button, TextField, Alert, CircularProgress } from '@mui/material';

function ImageConvertImageToSvg() {
  const [imageFile, setImageFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const MAX_FILE_SIZE = 100 * 1024;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage('파일 크기는 100kB를 초과할 수 없습니다.');
        setImageFile(null); // 파일 초기화
      } else {
        setErrorMessage(null); // 에러 초기화
        setImageFile(file); // 파일 설정
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!imageFile) {
      setErrorMessage('이미지 파일을 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('file', imageFile);

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/tool/image/convert-to-svg`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('이미지 파일 변환 중 오류가 발생했습니다.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'converted_image.svg';
      document.body.appendChild(a);
      a.click();
      a.remove();

      setImageFile(null); // 파일 선택 초기화
      setErrorMessage(null); // 에러 메시지 초기화
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>이미지 SVG 변환기 - 모두의 도구</title>
        <meta name="description" content="이미지를 SVG 포맷으로 변환하여 다운로드할 수 있는 도구입니다." />
        <meta property="og:title" content="이미지 SVG 변환기 - 모두의 도구" />
        <meta property="og:description" content="업로드한 이미지를 SVG 파일로 변환하여 다운로드하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        이미지를 SVG로 변환
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          type="file"
          fullWidth
          inputProps={{ accept: 'image/*' }}
          onChange={handleFileChange}
          variant="outlined"
          required
          sx={{ mb: 3 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? <CircularProgress size={24} /> : '변환 및 다운로드'}
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
}

export default ImageConvertImageToSvg;
