import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function LoanCalculator() {
  const [principal, setPrincipal] = useState('');
  const [annualInterestRate, setAnnualInterestRate] = useState('');
  const [loanTermYears, setLoanTermYears] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateLoan = async () => {
    const requestData = {
      principal: parseFloat(principal),
      annual_interest_rate: parseFloat(annualInterestRate),
      loan_term_years: parseFloat(loanTermYears),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/loan`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('대출 이자 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>대출 이자 계산기 - 모두의 도구</title>
        <meta name="description" content="대출 원금, 연이자율, 대출 기간을 기준으로 월 상환액과 총 상환액을 계산하는 대출 이자 계산기입니다." />
        <meta property="og:title" content="대출 이자 계산기 - 모두의 도구" />
        <meta property="og:description" content="대출 원금과 이자율을 입력하여 예상 월 상환액과 총 상환액을 확인하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        대출 이자 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="대출 원금 (원)"
          variant="outlined"
          value={principal}
          onChange={(e) => setPrincipal(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="연이자율 (%)"
          variant="outlined"
          value={annualInterestRate}
          onChange={(e) => setAnnualInterestRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="대출 기간 (년)"
          variant="outlined"
          value={loanTermYears}
          onChange={(e) => setLoanTermYears(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateLoan}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="대출 원금" secondary={`${result.principal.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="연이자율" secondary={`${result.annual_interest_rate} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="대출 기간" secondary={`${result.loan_term_years} 년`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="월 상환액" secondary={`${result.monthly_payment.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 상환액" secondary={`${result.total_payment.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 이자액" secondary={`${result.total_interest.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default LoanCalculator;
