import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function ExpenseManagementCalculator() {
  const [expenses, setExpenses] = useState({});
  const [savingsGoal, setSavingsGoal] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleExpenseChange = (category, value) => {
    setExpenses((prevExpenses) => ({
      ...prevExpenses,
      [category]: parseFloat(value) || 0,
    }));
  };

  const calculateExpenses = async () => {
    const requestData = {
      expenses,
      savings_goal: parseFloat(savingsGoal) / 100,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/expenses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('생활비 관리 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>생활비 관리 계산기 - 모두의 도구</title>
        <meta name="description" content="식비, 교통비 등 생활비를 관리하고 절약 목표를 설정하여 지출 항목별 절약 권장 금액을 계산하는 계산기입니다." />
        <meta property="og:title" content="생활비 관리 계산기 - 모두의 도구" />
        <meta property="og:description" content="생활비 지출 항목과 절약 목표를 입력하여 효율적인 지출 관리와 절약 금액을 손쉽게 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        생활비 관리 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">지출 항목 입력</Typography>
        <TextField
          fullWidth
          label="식비 (원)"
          variant="outlined"
          onChange={(e) => handleExpenseChange('식비', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="교통비 (원)"
          variant="outlined"
          onChange={(e) => handleExpenseChange('교통비', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="주거비 (원)"
          variant="outlined"
          onChange={(e) => handleExpenseChange('주거비', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="기타 (원)"
          variant="outlined"
          onChange={(e) => handleExpenseChange('기타', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="절약 목표 (%)"
          variant="outlined"
          value={savingsGoal}
          onChange={(e) => setSavingsGoal(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateExpenses}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="총 지출" secondary={`${result.total_expenses.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="절약 목표" secondary={`${result.savings_goal_percentage} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="절약 가능 금액" secondary={`${result.savings_target.toLocaleString()} 원`} />
            </ListItem>
            <Typography variant="h6" sx={{ mt: 2 }}>지출 항목별 절약 권장 금액</Typography>
            {Object.entries(result.savings_recommendations).map(([category, amount]) => (
              <ListItem key={category}>
                <ListItemText primary={category} secondary={`${amount.toLocaleString()} 원`} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Container>
  );
}

export default ExpenseManagementCalculator;
