import React, { useState } from 'react';
import { Container, TextField, Select, MenuItem, Button, Typography, Box, List, ListItem, ListItemText, InputLabel, FormControl, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NumericFormat } from 'react-number-format';
import { Helmet } from 'react-helmet';

function AcquisitionTaxCalculator() {
  const [acquisitionType, setAcquisitionType] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [acquisitionPrice, setAcquisitionPrice] = useState('');
  const [area, setArea] = useState('');
  const [residenceCount, setResidenceCount] = useState(1);
  const [isAdjustedArea, setIsAdjustedArea] = useState(false);
  const [isHeir, setIsHeir] = useState(false);
  const [nonprofit, setNonprofit] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateAcquisitionTax = async () => {
    const requestData = {
      acquisition_type: acquisitionType,
      property_type: propertyType,
      property_price: parseFloat(acquisitionPrice.replace(/,/g, '')), // 쉼표 제거 후 API로 전달
      area: parseFloat(area),
      residence_count: parseInt(residenceCount),
      is_adjusted_area: isAdjustedArea,
      is_heir: isHeir,
      nonprofit: nonprofit,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/acquisition-tax`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('취득세 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
      setError(''); // Reset error message on successful calculation
    } catch (error) {
      console.error('Error:', error);
      setError('취득세 계산 중 오류가 발생했습니다.'); // Set error message on failure
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>부동산 취득세 계산기</title>
        <meta name="description" content="부동산 취득세 및 관련 세금을 계산할 수 있는 유용한 도구입니다." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        부동산 취득세 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>취득 유형</InputLabel>
          <Select
            label="취득 유형"
            value={acquisitionType}
            onChange={(e) => setAcquisitionType(e.target.value)}
          >
            <MenuItem value="유상취득">유상취득</MenuItem>
            <MenuItem value="상속취득">상속취득</MenuItem>
            <MenuItem value="유증취득">유증취득</MenuItem>
            <MenuItem value="무상취득">무상취득</MenuItem>
            <MenuItem value="원시취득">원시취득</MenuItem>
            <MenuItem value="분할취득">분할취득</MenuItem>
            <MenuItem value="간주취득">간주취득</MenuItem>
          </Select>
        </FormControl>

        <Accordion elevation={0} sx={{ boxShadow: 'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" color="textSecondary">취득 유형 설명 보기</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="textSecondary">
              - <strong>유상취득</strong>: 매매, 경락 등으로 대가를 지급하고 취득하는 경우<br />
              - <strong>상속취득</strong>: 상속에 의해 부동산을 취득하는 경우<br />
              - <strong>유증취득</strong>: 유언에 의해 특정인에게 부동산을 취득하는 경우<br />
              - <strong>무상취득</strong>: 증여, 기부 등 대가 없이 취득하는 경우<br />
              - <strong>원시취득</strong>: 부동산을 신축, 증축, 개축 등을 통해 새로이 취득하는 경우<br />
              - <strong>분할취득</strong>: 공유물 분할, 총유물/합유물 분할로 인해 취득하는 경우<br />
              - <strong>간주취득</strong>: 지목변경, 과점주주 등의 사유로 간주하여 취득으로 보는 경우
            </Typography>
          </AccordionDetails>
        </Accordion>

        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel>부동산 유형</InputLabel>
          <Select
            label="부동산 유형"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <MenuItem value="주택">주택</MenuItem>
            <MenuItem value="건물">건물</MenuItem>
            <MenuItem value="농지">농지</MenuItem>
            <MenuItem value="상가">상가</MenuItem>
          </Select>
        </FormControl>

        <NumericFormat
          fullWidth
          label="취득 금액 (원)"
          variant="outlined"
          value={acquisitionPrice}
          onValueChange={(values) => setAcquisitionPrice(values.value)}
          thousandSeparator
          customInput={TextField}
          sx={{ mb: 2 }}
        />

        {(acquisitionType === '유상취득' || acquisitionType === '상속취득' || acquisitionType === '유증취득') && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>전용면적 (㎡)</InputLabel>
            <Select
              label="전용면적 (㎡)"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            >
              <MenuItem value={40}>40㎡ 이하</MenuItem>
              <MenuItem value={60}>60㎡ 이하</MenuItem>
              <MenuItem value={85}>85㎡ 이하</MenuItem>
              <MenuItem value={120}>120㎡ 이하</MenuItem>
              <MenuItem value={150}>150㎡ 이하</MenuItem>
              <MenuItem value={200}>200㎡ 이상</MenuItem>
            </Select>
          </FormControl>
        )}

        {acquisitionType === '유상취득' && propertyType === '주택' && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>주택 소유 수</InputLabel>
            <Select
              label="주택 소유 수"
              value={residenceCount}
              onChange={(e) => setResidenceCount(e.target.value)}
            >
              <MenuItem value={1}>1주택</MenuItem>
              <MenuItem value={2}>2주택</MenuItem>
              <MenuItem value={3}>3주택</MenuItem>
              <MenuItem value={4}>4주택 이상</MenuItem>
            </Select>
          </FormControl>
        )}

        {acquisitionType === '유상취득' && propertyType === '주택' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdjustedArea}
                onChange={(e) => setIsAdjustedArea(e.target.checked)}
              />
            }
            label="조정대상지역 여부"
          />
        )}

        {acquisitionType === '유증취득' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isHeir}
                onChange={(e) => setIsHeir(e.target.checked)}
              />
            }
            label="법정상속인 여부"
          />
        )}

        {acquisitionType === '무상취득' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={nonprofit}
                onChange={(e) => setNonprofit(e.target.checked)}
              />
            }
            label="비영리사업자 여부"
          />
        )}

        <Button variant="contained" color="primary" onClick={calculateAcquisitionTax}>
          계산하기
        </Button>

        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="취득 금액" secondary={`${result.property_price.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="부동산 유형" secondary={result.property_type} />
            </ListItem>
            <ListItem>
              <ListItemText primary="취득세율" secondary={`${(result.acquisition_tax_rate * 100).toFixed(1)} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="취득세" secondary={`${result.acquisition_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="지방교육세" secondary={`${result.local_education_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="농어촌특별세" secondary={`${result.rural_special_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 세금" secondary={`${result.total_tax.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}

      <Typography variant="body2" color="textSecondary" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
        * 부동산 거래 시 부과되는 취득세 예상 금액을 산정하기 위한 참고용 계산기이며, 실제 세액과는 다소 차이가 있을 수 있습니다.
      </Typography>
    </Container>
  );
}

export default AcquisitionTaxCalculator;
