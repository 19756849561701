import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Snackbar, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet';
import './user.css';

export default function UserList() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/user/list`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setUsers(response.data);
      } catch (err) {
        setError('사용자 목록을 가져오는 중 오류가 발생했습니다.');
        setSnackbarOpen(true); // 오류 발생 시 스낵바 열기
        console.error("Error fetching user list:", err);
      } finally {
        setLoading(false); // 로딩 종료
      }
    };

    fetchUsers();
  }, [API_URL, accessToken]);

  const handleEditUser = (userId) => {
    navigate(`/user/edit/${userId}`);
  };

  return (
    <div>
      <Helmet>
        <title>회원 목록 - 사이트 이름</title>
      </Helmet>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
          <Typography variant="h6" style={{ marginLeft: '10px' }}>사용자 목록을 불러오는 중입니다...</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="user table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>아바타</TableCell>
                <TableCell>아이디</TableCell>
                <TableCell>이메일</TableCell>
                <TableCell>가입일</TableCell>
                <TableCell>회원 등급</TableCell>
                <TableCell>수정</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>
                    <img
                      src={user.avatar || 'https://via.placeholder.com/50'}
                      alt={`${user.username}'s avatar`}
                      width="50"
                      height="50"
                    />
                  </TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{new Date(user.join_date).toLocaleDateString()}</TableCell>
                  <TableCell>{user.grade}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditUser(user.id)}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={error}
      />
    </div>
  );
}
