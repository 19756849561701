import React from "react";
import { Container, Typography, Box, TextField, Button } from "@mui/material";

function ContactPage() {
  const API_URL = process.env.REACT_APP_API_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      content: e.target.content.value,
    };

    try {
      const response = await fetch(`${API_URL}/api/question/customer-inquiry`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("문의가 성공적으로 접수되었습니다. 확인 후 이메일로 답변드리겠습니다.");
      } else {
        alert("문의 접수에 실패했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      alert("서버 오류가 발생했습니다. 나중에 다시 시도해주세요.");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ py: 1 }}>
        고객센터 문의
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="name"
          label="이름"
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />
        <TextField
          fullWidth
          name="email"
          label="이메일"
          type="email"
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />
        <TextField
          fullWidth
          name="content"
          label="문의 내용"
          variant="outlined"
          multiline
          rows={4}
          sx={{ mb: 3 }}
          required
        />
        <Button variant="contained" color="primary" type="submit">
          문의하기
        </Button>
      </Box>
    </Container>
  );
}

export default ContactPage;
