import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function VATCalculator() {
  const [price, setPrice] = useState('');
  const [vatRate, setVatRate] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateVAT = async () => {
    const requestData = {
      price: parseFloat(price),
      vat_rate: parseFloat(vatRate),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/vat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('부가가치세 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>부가가치세 계산기 - 모두의 도구</title>
        <meta name="description" content="입력된 가격과 부가가치세율을 기준으로 부가가치세 포함 및 제외 가격을 계산하는 부가가치세 계산기입니다." />
        <meta property="og:title" content="부가가치세 계산기 - 모두의 도구" />
        <meta property="og:description" content="가격과 세율을 입력하여 부가가치세 금액과 세금 포함 가격을 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        부가가치세 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="가격 (원)"
          variant="outlined"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="부가가치세율 (%)"
          variant="outlined"
          value={vatRate}
          onChange={(e) => setVatRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateVAT}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="입력된 가격" secondary={`${result.input_price.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="부가가치세율" secondary={`${result.vat_rate} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="부가가치세 제외 가격" secondary={`${result.vat_exclusive_price.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="부가가치세 포함 가격" secondary={`${result.vat_inclusive_price.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="부가가치세 금액" secondary={`${result.vat_amount.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default VATCalculator;
