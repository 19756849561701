import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Select, MenuItem, Button, Typography, Box, List, ListItem, ListItemText, InputLabel, FormControl, CircularProgress, Snackbar } from '@mui/material';

function Naver() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('blog');
  const [sort, setSort] = useState('sim');
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const updateSortOptions = (event) => {
    const newSearchType = event.target.value;
    setSearchType(newSearchType);
    setSort(newSearchType === 'shop' ? 'asc' : 'sim');
  };

  const crawlData = async () => {
    setLoading(true);
    setResults([]);
    setShowResults(false);

    // JSON 형식으로 데이터 준비
    const payload = {
      search_query: searchQuery,
      search_type: searchType,
      sort: sort,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/search/naver`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      
      if (!response.ok) {
        throw new Error('네트워크 응답이 실패했습니다.');
      }

      const data = await response.json();
      setResults(data.items || []);
      setShowResults(true);
    } catch (error) {
      console.error('Error:', error);
      setError('검색 중 문제가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await fetch(`${API_URL}/api/tool/download-excel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items: results }),
      });
      if (!response.ok) {
        throw new Error('엑셀 파일 생성에 실패했습니다.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'naver_search_results.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error:', error);
      setError('엑셀 다운로드 중 문제가 발생했습니다.');
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Helmet>
        <title>네이버 검색 데이터 엑셀 다운로드</title>
        <meta name="description" content="네이버에서 원하는 검색어로 데이터를 크롤링하고, 검색 결과를 엑셀 파일로 다운로드하는 기능을 제공합니다." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        네이버 검색 데이터 엑셀 다운로드
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="검색어"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>검색 유형</InputLabel>
          <Select label="검색 유형" value={searchType} onChange={updateSortOptions}>
            <MenuItem value="blog">블로그</MenuItem>
            <MenuItem value="news">뉴스</MenuItem>
            <MenuItem value="shop">쇼핑</MenuItem>
            <MenuItem value="cafearticle">카페글</MenuItem>
            <MenuItem value="kin">지식iN</MenuItem>
            <MenuItem value="encyc">백과사전</MenuItem>
            <MenuItem value="webkr">웹문서</MenuItem>
            <MenuItem value="image">이미지</MenuItem>
            <MenuItem value="doc">전문자료</MenuItem>
            <MenuItem value="book">책</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>정렬</InputLabel>
          <Select label="정렬" value={sort} onChange={(e) => setSort(e.target.value)}>
            <MenuItem value="sim">정확도순</MenuItem>
            <MenuItem value="date">날짜순</MenuItem>
            {searchType === 'shop' && (
              <>
                <MenuItem value="asc">가격 낮은 순</MenuItem>
                <MenuItem value="dsc">가격 높은 순</MenuItem>
              </>
            )}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={crawlData} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Data 검색'}
        </Button>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>
          검색중입니다. 잠시만 기다려주세요...
          </Typography>
        </Box>
      )}

      {showResults && !loading && (
        <Box sx={{ mt: 4 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">검색 결과</Typography>
            <Button variant="contained" color="success" onClick={downloadExcel}>
              엑셀 다운로드
            </Button>
          </Box>
          {results.length > 0 ? (
            <List>
              {results.map((item, index) => (
                <ListItem key={index} sx={{ borderBottom: '1px solid #ccc' }}>
                  <ListItemText
                    primary={item.title || '제목 없음'}
                    secondary={Object.entries(item).map(([key, value]) =>
                      key !== 'title' ? (
                        <Typography key={key} component="p" variant="body2" color="text.secondary">
                          <strong>{key}:</strong> {value}
                        </Typography>
                      ) : null
                    )}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" color="text.secondary">
              검색 결과가 없습니다.
            </Typography>
          )}
        </Box>
      )}

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Container>
  );
}

export default Naver;
