import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TaxCalculator = () => {
  const [estateValue, setEstateValue] = useState('');
  const [giftValue, setGiftValue] = useState('');
  const [isSpouse, setIsSpouse] = useState(false);
  const [inheritanceTax, setInheritanceTax] = useState(null);
  const [giftTax, setGiftTax] = useState(null);

  const formatInput = (value) => {
    const numericValue = value.replace(/[^\d]/g, '');
    return numericValue ? parseInt(numericValue).toLocaleString() : '';
  };

  const calculateInheritanceTax = () => {
    const value = parseFloat(estateValue.replace(/,/g, ''));
    if (isNaN(value)) {
      alert('유효한 금액을 입력해주세요.');
      return;
    }

    let deduction = 200000000; // 기본 공제 2억원
    if (isSpouse) {
      deduction += Math.min(value * 0.3, 500000000); // 배우자 공제: 30% 또는 5억원 중 작은 금액
    }

    let taxableAmount = Math.max(value - deduction, 0);
    let tax = 0;

    if (taxableAmount > 3000000000) {
      tax += (taxableAmount - 3000000000) * 0.5;
      taxableAmount = 3000000000;
    }
    if (taxableAmount > 1000000000) {
      tax += (taxableAmount - 1000000000) * 0.4;
      taxableAmount = 1000000000;
    }
    if (taxableAmount > 500000000) {
      tax += (taxableAmount - 500000000) * 0.3;
      taxableAmount = 500000000;
    }
    if (taxableAmount > 100000000) {
      tax += (taxableAmount - 100000000) * 0.2;
      taxableAmount = 100000000;
    }
    tax += taxableAmount * 0.1;

    setInheritanceTax(tax);
  };

  const calculateGiftTax = () => {
    const value = parseFloat(giftValue.replace(/,/g, ''));
    if (isNaN(value)) {
      alert('유효한 금액을 입력해주세요.');
      return;
    }

    let deduction = isSpouse ? 600000000 : 50000000; // 배우자: 6억원, 기타: 5천만원
    let taxableAmount = Math.max(value - deduction, 0);
    let tax = 0;

    if (taxableAmount > 3000000000) {
      tax += (taxableAmount - 3000000000) * 0.5;
      taxableAmount = 3000000000;
    }
    if (taxableAmount > 1000000000) {
      tax += (taxableAmount - 1000000000) * 0.4;
      taxableAmount = 1000000000;
    }
    if (taxableAmount > 500000000) {
      tax += (taxableAmount - 500000000) * 0.3;
      taxableAmount = 500000000;
    }
    if (taxableAmount > 100000000) {
      tax += (taxableAmount - 100000000) * 0.2;
      taxableAmount = 100000000;
    }
    tax += taxableAmount * 0.1;

    setGiftTax(tax);
  };

  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        상속세 및 증여세 계산기
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>상속세 계산</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textSecondary" paragraph>
            상속세는 사망으로 인해 재산을 무상으로 취득한 경우에 부과되는 세금입니다. 
            기본적으로 2억원의 기초공제가 적용되며, 배우자의 경우 추가 공제가 있습니다.
            과세표준에 따라 10%에서 50%까지의 누진세율이 적용됩니다.
          </Typography>
          <TextField
            label="상속 재산 가치 (원)"
            fullWidth
            value={estateValue}
            onChange={(e) => setEstateValue(formatInput(e.target.value))}
            margin="normal"
          />
          <FormControlLabel
            control={<Checkbox checked={isSpouse} onChange={(e) => setIsSpouse(e.target.checked)} />}
            label="배우자에게 상속"
          />
          <Button variant="contained" onClick={calculateInheritanceTax} sx={{ mt: 2 }}>
            상속세 계산
          </Button>
          {inheritanceTax !== null && (
            <Box mt={2}>
              <Typography>
                예상 상속세: {formatNumber(inheritanceTax.toFixed(0))} 원
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>증여세 계산</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textSecondary" paragraph>
            증여세는 타인으로부터 재산을 무상으로 받은 경우에 부과되는 세금입니다. 
            배우자의 경우 6억원, 기타의 경우 5천만원의 기본 공제가 적용됩니다.
            과세표준에 따라 10%에서 50%까지의 누진세율이 적용됩니다.
          </Typography>
          <TextField
            label="증여 가치 (원)"
            fullWidth
            value={giftValue}
            onChange={(e) => setGiftValue(formatInput(e.target.value))}
            margin="normal"
          />
          <FormControlLabel
            control={<Checkbox checked={isSpouse} onChange={(e) => setIsSpouse(e.target.checked)} />}
            label="배우자에게 증여"
          />
          <Button variant="contained" onClick={calculateGiftTax} sx={{ mt: 2 }}>
            증여세 계산
          </Button>
          {giftTax !== null && (
            <Box mt={2}>
              <Typography>
                예상 증여세: {formatNumber(giftTax.toFixed(0))} 원
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default TaxCalculator;
