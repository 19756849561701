import React, { useEffect, useState } from "react";
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import moment from 'moment';
import 'moment/locale/ko';

function CustomerInquiriesPage() {
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  moment.locale('ko');
  
  useEffect(() => {
    // 데이터 가져오기
    const API_URL = process.env.REACT_APP_API_URL;
    const fetchInquiries = async () => {
      try {
        const response = await fetch(`${API_URL}/api/question/customer-inquiries`);
        if (!response.ok) {
          throw new Error("데이터를 불러오지 못했습니다.");
        }
        const data = await response.json();
        setInquiries(data.inquiries);
      } catch (error) {
        console.error("Error fetching customer inquiries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInquiries();
  }, []);

  if (loading) {
    return (
      <Container>
        <Typography variant="h6" align="center" sx={{ mt: 5 }}>
          로딩 중...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        고객센터 요청 목록
      </Typography>
      {inquiries.length === 0 ? (
        <Typography variant="body1" align="center" sx={{ mt: 3 }}>
          현재 접수된 요청이 없습니다.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>번호</TableCell>
                <TableCell>이름</TableCell>
                <TableCell>이메일</TableCell>
                <TableCell>내용</TableCell>
                <TableCell>작성일</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inquiries.map((inquiry, index) => (
                <TableRow key={inquiry.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{inquiry.name}</TableCell>
                  <TableCell>{inquiry.email}</TableCell>
                  <TableCell>{inquiry.content}</TableCell>
                  <TableCell align="center">{moment(inquiry.create_date).format("YYYY년 MM월 DD일 HH:mm")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
}

export default CustomerInquiriesPage;
