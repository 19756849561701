import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';

function CarRegistrationTaxCalculator() {
  const [carPrice, setCarPrice] = useState('');
  const [carType, setCarType] = useState('');
  const [carCategory, setCarCategory] = useState('');
  const [cc, setCc] = useState(''); // 배기량 선택
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateCarRegistrationTax = async () => {
    const requestData = {
      car_price: carPrice.replace(/,/g, ''), // 쉼표 제거 후 API로 전달
      car_type: carType,
      car_category: carCategory,
      cc: carCategory === "이륜차" ? cc : null, // 이륜차일 때만 cc 전달
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/car-registration-tax`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('자동차 등록세 및 취득세 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>자동차 등록세 및 취득세 계산기</title>
        <meta name="description" content="자동차의 등록세와 취득세를 계산해주는 도구입니다. 영업용, 비영업용 차량에 따라 등록세와 취득세를 정확하게 계산합니다." />
      </Helmet>
      <Typography variant="h4" gutterBottom>
        자동차 등록세 및 취득세 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>카테고리 선택</InputLabel>
          <Select
            value={carType}
            onChange={(e) => setCarType(e.target.value)}
            label="카테고리 선택"
          >
            <MenuItem value="영업용">영업용</MenuItem>
            <MenuItem value="비영업용">비영업용</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>차량 종류</InputLabel>
          <Select
            value={carCategory}
            onChange={(e) => setCarCategory(e.target.value)}
            label="차량 종류"
          >
            <MenuItem value="경차">경차</MenuItem>
            <MenuItem value="승용차">승용차</MenuItem>
            <MenuItem value="승합차 (7~10인승)">승합차 (7~10인승)</MenuItem>
            <MenuItem value="승합차 (11인승 이상)">승합차 (11인승 이상)</MenuItem>
            <MenuItem value="화물차">화물차</MenuItem>
            <MenuItem value="이륜차">이륜차</MenuItem>
          </Select>
        </FormControl>

        {carCategory === "이륜차" && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>배기량 (cc)</InputLabel>
            <Select
              value={cc}
              onChange={(e) => setCc(e.target.value)}
              label="배기량 (cc)"
            >
              <MenuItem value="125이상">125cc 이상</MenuItem>
              <MenuItem value="125미만">125cc 미만</MenuItem>
              <MenuItem value="50미만">50cc 미만</MenuItem>
            </Select>
          </FormControl>
        )}

        <NumericFormat
          fullWidth
          label="차량 가격 (부가가치세 포함, 원)"
          variant="outlined"
          value={carPrice}
          onValueChange={(values) => setCarPrice(values.value)}
          thousandSeparator
          customInput={TextField}
          sx={{ mb: 2 }}
        />

        <Button variant="contained" color="primary" onClick={calculateCarRegistrationTax} disabled={!carType || !carPrice || !carCategory || (carCategory === "이륜차" && !cc)}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="차량 가격 (부가가치세 포함)" secondary={`${result.car_price_including_vat.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="차량 가격 (부가가치세 제외)" secondary={`${result.car_price.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`등록세 (${result.registration_tax_rate}%)`} secondary={`${result.registration_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`취득세 (${result.acquisition_tax_rate}%)`} secondary={`${result.acquisition_tax.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 세금" secondary={`${result.total_tax.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
      <Typography variant="body2" color="textSecondary" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
        * 2024년 11월 기준으로 계산된 결과입니다. 최신 법률 또는 정책에 따라서 차이가 있을 수 있습니다.
      </Typography>
    </Container>
  );
}

export default CarRegistrationTaxCalculator;
