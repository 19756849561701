import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function SeveranceCalculator() {
  const [annualSalary, setAnnualSalary] = useState('');
  const [yearsOfService, setYearsOfService] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateSeverance = async () => {
    const requestData = {
      annual_salary: parseFloat(annualSalary),
      years_of_service: parseFloat(yearsOfService),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/severance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('퇴직금 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>퇴직금 계산기 - 모두의 도구</title>
        <meta name="description" content="연봉과 근속 연수를 기준으로 퇴직금을 계산하는 퇴직금 계산기입니다." />
        <meta property="og:title" content="퇴직금 계산기 - 모두의 도구" />
        <meta property="og:description" content="연봉과 근속 연수를 입력하여 예상 퇴직금을 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        퇴직금 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="연봉 (원)"
          variant="outlined"
          value={annualSalary}
          onChange={(e) => setAnnualSalary(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="근속 연수 (년)"
          variant="outlined"
          value={yearsOfService}
          onChange={(e) => setYearsOfService(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateSeverance}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="연봉" secondary={`${result.annual_salary.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="근속 연수" secondary={`${result.years_of_service} 년`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="1일 평균 임금" secondary={`${result.daily_average_salary.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="퇴직금" secondary={`${result.severance_pay.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default SeveranceCalculator;
