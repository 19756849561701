import React, { useState } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const API_URL = process.env.REACT_APP_API_URL;

const InputWithAccordion = ({ label, value, onChange, explanation }) => (
  <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
    <Grid item xs={12}>
      <TextField
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
      />
    </Grid>
    <Grid item xs={12}>
      <Accordion elevation={0} sx={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" color="textSecondary">설명 보기</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textSecondary">
            {explanation}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </Grid>
);

function TransferTaxCalculator() {
  const [transferPrice, setTransferPrice] = useState("");
  const [acquisitionPrice, setAcquisitionPrice] = useState("");
  const [expenses, setExpenses] = useState("0");
  const [holdingPeriod, setHoldingPeriod] = useState("");
  const [isPrimaryResidence, setIsPrimaryResidence] = useState(false);
  const [additionalDeduction, setAdditionalDeduction] = useState("0");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const formatNumber = (value) => {
    const number = value.replace(/[^\d]/g, '');
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleNumberChange = (setter) => (event) => {
    const formattedValue = formatNumber(event.target.value);
    setter(formattedValue);
  };

  const calculateTransferTax = async () => {
    setLoading(true);
    setResult(null);

    const payload = {
      transfer_price: parseFloat(transferPrice.replace(/,/g, '')),
      acquisition_price: parseFloat(acquisitionPrice.replace(/,/g, '')),
      expenses: parseFloat(expenses.replace(/,/g, '')),
      holding_period: parseInt(holdingPeriod, 10),
      is_primary_residence: isPrimaryResidence,
      additional_deduction: parseFloat(additionalDeduction.replace(/,/g, '')),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/transfer-tax`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error("양도세 계산 실패");

      const data = await response.json();
      setResult(data);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        양도세 계산기
      </Typography>

      <InputWithAccordion
        label="양도가격 (원)"
        value={transferPrice}
        onChange={handleNumberChange(setTransferPrice)}
        explanation="실제 판매한 금액입니다."
      />

      <InputWithAccordion
        label="취득가격 (원)"
        value={acquisitionPrice}
        onChange={handleNumberChange(setAcquisitionPrice)}
        explanation="자산을 구입할 때 지불한 금액입니다."
      />

      <InputWithAccordion
        label="필요경비 (원)"
        value={expenses}
        onChange={handleNumberChange(setExpenses)}
        explanation="거래와 관련된 중개수수료, 등기비용 등의 경비입니다."
      />

      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>보유기간 (년)</InputLabel>
            <Select
              value={holdingPeriod}
              onChange={(e) => setHoldingPeriod(e.target.value)}
            >
              {[...Array(31).keys()].map((year) => (
                <MenuItem key={year} value={year}>
                  {year}년
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Accordion elevation={0} sx={{ boxShadow: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1" color="textSecondary">설명 보기</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                소유한 기간입니다. 장기보유 특별공제에 영향을 줍니다.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPrimaryResidence}
                onChange={(e) => setIsPrimaryResidence(e.target.checked)}
              />
            }
            label="1세대 1주택"
          />
        </Grid>
        <Grid item xs={12}>
          <Accordion elevation={0} sx={{ boxShadow: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1" color="textSecondary">설명 보기</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                1세대가 1주택만 소유한 경우 체크하세요. 비과세 혜택을 받을 수 있습니다.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <InputWithAccordion
        label="추가 공제 (원)"
        value={additionalDeduction}
        onChange={handleNumberChange(setAdditionalDeduction)}
        explanation="장애인 공제, 고령자 공제 등 추가적인 공제 금액입니다."
      />

      <Button
        variant="contained"
        color="primary"
        onClick={calculateTransferTax}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "계산하기"}
      </Button>

      {result && (
        <Box sx={{ mt: 4, p: 3, border: 1, borderColor: 'grey.300', borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom>
            계산 결과
          </Typography>
          <Typography variant="body1">
            양도소득세: {result.transfer_tax?.toLocaleString()} 원
          </Typography>
          <Typography variant="body1">
            과세표준: {result.tax_base?.toLocaleString()} 원
          </Typography>
          <Typography variant="body1">
            적용 세율: {(result.tax_rate * 100)?.toFixed(1)}%
          </Typography>
          <Typography variant="body1">
            장기보유특별공제: {result.long_term_holding_deduction?.toLocaleString()} 원
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line', mt: 2 }}>
            {result.calculation_details}
          </Typography>
        </Box>
      )}
      <Typography variant="body2" color="textSecondary" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
        * 양도소득세를 산정하기 위한 참고용 계산기이며, 실제 세액과는 다소 차이가 있을 수 있습니다.
      </Typography>
    </Container>
  );
}

export default TransferTaxCalculator;
