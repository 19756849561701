import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, TextField, Button, Alert } from '@mui/material';

function AnswerModify() {
  const { answer_id } = useParams();
  const [questionId, setQuestionId] = useState(0);
  const [content, setContent] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${API_URL}/api/answer/detail/${answer_id}`)
      .then(response => {
        const json = response.data;
        setQuestionId(json.question_id);
        setContent(json.content);
      })
      .catch(err => {
        console.error("Failed to fetch answer details:", err);
        setError('답변 정보를 가져오는 중 오류가 발생했습니다.');
      });
  }, [answer_id, API_URL]);

  const updateAnswer = (event) => {
    event.preventDefault();
    const url = `${API_URL}/api/answer/update`;
    const params = {
      answer_id: answer_id,
      content: content,
    };
    
    axios.put(url, params)
      .then(() => {
        navigate(`/detail/${questionId}`);
      })
      .catch(err => {
        console.error("Failed to update answer:", err);
        setError('답변을 수정하는 중 오류가 발생했습니다.');
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Helmet>
        <title>답변 수정 - Q&A 서비스</title>
        <meta name="description" content="기존에 작성한 답변을 수정할 수 있습니다." />
      </Helmet>

      <Typography variant="h5" component="h1" gutterBottom>
        답변 수정
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={updateAnswer} sx={{ mt: 2 }}>
        <TextField
          label="내용"
          fullWidth
          variant="outlined"
          multiline
          rows={10}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          수정하기
        </Button>
      </Box>
    </Container>
  );
}

export default AnswerModify;
