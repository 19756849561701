import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Select, MenuItem, Button, Typography, Box, List, ListItem, ListItemText, InputLabel, FormControl } from '@mui/material';

function InsuranceCalculator() {
  const [insuranceType, setInsuranceType] = useState('생명보험');
  const [age, setAge] = useState('');
  const [insuredAmount, setInsuredAmount] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateInsurance = async () => {
    const requestData = {
      insurance_type: insuranceType,
      age: parseInt(age, 10),
      insured_amount: parseFloat(insuredAmount),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/insurance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('보험료 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>보험료 계산기 - 모두의 도구</title>
        <meta name="description" content="보험 유형, 나이, 보험 금액을 기반으로 보험료와 보험금액 대비 보험료 수준을 계산하는 보험료 계산기입니다." />
        <meta property="og:title" content="보험료 계산기 - 모두의 도구" />
        <meta property="og:description" content="생명보험과 건강보험의 보험료를 나이와 보험 금액을 기준으로 간편하게 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        보험료 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>보험 유형</InputLabel>
          <Select
            label="보험 유형"
            value={insuranceType}
            onChange={(e) => setInsuranceType(e.target.value)}
          >
            <MenuItem value="생명보험">생명보험</MenuItem>
            <MenuItem value="건강보험">건강보험</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="나이"
          variant="outlined"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="보험 금액 (원)"
          variant="outlined"
          value={insuredAmount}
          onChange={(e) => setInsuredAmount(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateInsurance}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="보험 유형" secondary={result.insurance_type} />
            </ListItem>
            <ListItem>
              <ListItemText primary="나이" secondary={`${result.age} 세`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="보험 금액" secondary={`${result.insured_amount.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="기본 요율" secondary={`${(result.base_rate * 100).toFixed(2)} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="보험료" secondary={`${result.premium.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="보험금액 대비 보험료 수준" secondary={`${result.premium_level_percentage} %`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default InsuranceCalculator;
