import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const NotFoundPage = () => {
    return (
        <Box sx={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography variant="body1" paragraph>
                요청하신 페이지가 존재하지 않습니다.
            </Typography>
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
                startIcon={<HomeIcon />}
            >
                홈으로 돌아가기
            </Button>
        </Box>
    );
};

export default NotFoundPage;
