import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText, CircularProgress, Snackbar } from '@mui/material';

function Google() {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const crawlData = async () => {
    setLoading(true);  // 로딩 상태 시작
    setResults([]);
    setShowResults(false);

    // JSON 형식으로 데이터 준비
    const payload = {
      search_query: searchQuery,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/search/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setResults(data.items || []);
      setShowResults(true);
    } catch (error) {
      console.error('Error:', error);
      setError('검색 중 문제가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setLoading(false);  // 로딩 상태 종료
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await fetch(`${API_URL}/api/tool/download-excel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items: results }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'google_search_results.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error:', error);
      setError('엑셀 다운로드 중 문제가 발생했습니다.');
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Helmet>
        <title>Google 검색 및 엑셀 다운로드</title>
        <meta name="description" content="Google 검색 결과를 조회하고 엑셀 파일로 다운로드할 수 있는 기능을 제공합니다." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        Google 검색 및 엑셀 다운로드
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="검색어"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={crawlData} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Data 검색'}
        </Button>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>
            검색중입니다. 잠시만 기다려주세요...
          </Typography>
        </Box>
      )}

      {showResults && (
        <Box sx={{ mt: 4 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">검색 결과</Typography>
            <Button variant="contained" color="success" onClick={downloadExcel}>
              엑셀 다운로드
            </Button>
          </Box>
          <List>
            {results.map((item, index) => (
              <ListItem key={index} sx={{ borderBottom: '1px solid #ccc' }}>
                <ListItemText
                  primary={`Title: ${item.title}`}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.secondary">
                        {item.snippet}
                      </Typography>
                      <br />
                      <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>
                        {item.link}
                      </a>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Container>
  );
}

export default Google;
