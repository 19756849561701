import React from 'react';
import { Route, Routes } from 'react-router-dom';
import QuestionIndex from '../components/questions/QuestionIndex';
import QuestionDetail from '../components/questions/QuestionDetail';
import QuestionCreate from '../components/questions/QuestionCreate';
import QuestionModify from '../components/questions/QuestionModify';
import AnswerModify from '../components/questions/AnswerModify';
import CustomerInquiriesPage from "../components/questions/CustomerInquiriesPage";
import NotFoundPage from '../components/common/NotFoundPage';

function QuestionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<QuestionIndex />} />
      <Route path="detail/:id" element={<QuestionDetail />} />
      <Route path="create" element={<QuestionCreate />} />
      <Route path="modify/:question_id" element={<QuestionModify />} />
      <Route path="answer/modify/:id" element={<AnswerModify />} />
      <Route path="customer-inquiries" element={<CustomerInquiriesPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default QuestionRoutes;
