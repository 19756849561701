import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // react-helmet import 추가
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function CarInstallmentCalculator() {
  const [carPrice, setCarPrice] = useState('');
  const [annualInterestRate, setAnnualInterestRate] = useState('');
  const [installmentTermMonths, setInstallmentTermMonths] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateCarInstallment = async () => {
    const requestData = {
      car_price: parseFloat(carPrice),
      annual_interest_rate: parseFloat(annualInterestRate),
      installment_term_months: parseInt(installmentTermMonths, 10),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/car-installment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('자동차 할부 이자 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>자동차 할부 이자 계산기 - 모두의 도구</title>
        <meta name="description" content="자동차 할부 계약 시 매달 상환할 금액과 총 이자를 계산해주는 자동차 할부 이자 계산기입니다." />
        <meta property="og:title" content="자동차 할부 이자 계산기 - 모두의 도구" />
        <meta property="og:description" content="자동차 할부 계약 시 월별 상환액과 총 이자를 간편하게 계산해 보세요." />
      </Helmet>
      
      <Typography variant="h4" gutterBottom>
        자동차 할부 이자 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="차량 가격 (원)"
          variant="outlined"
          value={carPrice}
          onChange={(e) => setCarPrice(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="연이자율 (%)"
          variant="outlined"
          value={annualInterestRate}
          onChange={(e) => setAnnualInterestRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="할부 기간 (개월)"
          variant="outlined"
          value={installmentTermMonths}
          onChange={(e) => setInstallmentTermMonths(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateCarInstallment}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="차량 가격" secondary={`${result.car_price.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="연이자율" secondary={`${result.annual_interest_rate} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="할부 기간" secondary={`${result.installment_term_months} 개월`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="월 상환액" secondary={`${result.monthly_payment.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 상환액" secondary={`${result.total_payment.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="총 이자액" secondary={`${result.total_interest.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
      <Typography variant="body2" color="textSecondary" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
        * 일반적인 참고용으로 계산된 결과이고, 개개인의 계약에 따라서 차이가 있을 수 있습니다.
      </Typography>
    </Container>
  );
}

export default CarInstallmentCalculator;
