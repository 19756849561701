import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, TextField, Typography } from '@mui/material';

function QuestionCreate() {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const { accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const postQuestion = (event) => {
    event.preventDefault();
    const url = `${API_URL}/api/question/create`;
    const params = {
      subject: subject,
      content: content,
    };

    axios.post(url, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(() => {
        navigate('/question');
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          navigate('/user/login');
        } else {
          console.error("Failed to create question:", err);
        }
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Helmet>
        <title>질문 등록 - Q&A 서비스</title>
        <meta name="description" content="새로운 질문을 등록하고 Q&A 커뮤니티와 소통하세요." />
      </Helmet>

      <Typography variant="h5" component="h1" gutterBottom>
        질문 등록
      </Typography>
      <Box component="form" onSubmit={postQuestion} sx={{ mt: 2 }}>
        <TextField
          label="제목"
          fullWidth
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          label="내용"
          fullWidth
          variant="outlined"
          multiline
          rows={10}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          등록
        </Button>
      </Box>
    </Container>
  );
}

export default QuestionCreate;
