import React from "react";
import { Container, Typography, Box, Paper, Divider } from "@mui/material";

function TermsPage() {
  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          이용약관
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 2 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              제1조 목적
            </Typography>
            <Typography variant="body1" paragraph>
              본 약관은 "모두의 도구"가 제공하는 서비스의 이용 조건 및 절차, 기타 필요한 사항을 규정하는 것을 목적으로 합니다.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              제2조 이용약관의 효력 및 변경
            </Typography>
            <Typography variant="body1" paragraph>
              본 약관은 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력을 발생합니다. 회사는 필요 시 약관을 변경할 수 있으며,
              변경된 약관은 공지사항 등을 통해 안내됩니다.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* 다른 조항을 여기에 추가 */}
        </Box>
      </Paper>
    </Container>
  );
}

export default TermsPage;
