import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';

// axios 인터셉터 설정
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function QuestionModify() {
  const { question_id } = useParams();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    axios.get(`${API_URL}/api/question/detail/${question_id}`)
      .then(response => {
        setSubject(response.data.subject);
        setContent(response.data.content);
      })
      .catch(err => {
        console.error(err);
        setSnackbar({ open: true, message: '질문 정보를 불러오는데 실패했습니다.', severity: 'error' });
      })
      .finally(() => setLoading(false));
  }, [question_id, API_URL]);

  const updateQuestion = async (event) => {
    event.preventDefault();
    if (!subject.trim() || !content.trim()) {
      setSnackbar({ open: true, message: '제목과 내용을 모두 입력해주세요.', severity: 'warning' });
      return;
    }

    setLoading(true);
    const url = `${API_URL}/api/question/update`;
    const params = { question_id, subject, content };

    try {
      const response = await axios.put(url, params);
      console.log('Update successful:', response.status);
      setSnackbar({ open: true, message: '질문이 성공적으로 수정되었습니다.', severity: 'success' });
      setTimeout(() => navigate(`/question/detail/${question_id}`), 1500);
    } catch (err) {
      console.error('Error:', err.response?.data || err.message);
      if (err.response && err.response.status === 401) {
        setSnackbar({ open: true, message: '인증에 실패했습니다. 로그인 상태를 확인해주세요.', severity: 'error' });
      } else {
        setSnackbar({ open: true, message: '질문 수정에 실패했습니다. 다시 시도해주세요.', severity: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/question/detail/${question_id}`);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Helmet>
        <title>질문 수정</title>
        <meta name="description" content="기존 질문의 제목과 내용을 수정할 수 있는 페이지입니다." />
      </Helmet>
      <Typography variant="h5" component="h1" gutterBottom>
        질문 수정
      </Typography>
      <Box component="form" onSubmit={updateQuestion} sx={{ mt: 2 }}>
        <TextField
          label="제목"
          fullWidth
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          label="내용"
          fullWidth
          variant="outlined"
          multiline
          rows={10}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          margin="normal"
          required
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleCancel} sx={{ width: '48%' }}>
            취소
          </Button>
          <Button type="submit" variant="contained" color="primary" sx={{ width: '48%' }}>
            수정하기
          </Button>
        </Box>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default QuestionModify;