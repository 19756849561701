import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function MortgageCalculator() {
  const [propertyValue, setPropertyValue] = useState('');
  const [ltvRatio, setLtvRatio] = useState('');
  const [expectedInterestRate, setExpectedInterestRate] = useState('');
  const [loanTermYears, setLoanTermYears] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateMortgage = async () => {
    const requestData = {
      property_value: parseFloat(propertyValue),
      ltv_ratio: parseFloat(ltvRatio),
      expected_interest_rate: parseFloat(expectedInterestRate),
      loan_term_years: parseFloat(loanTermYears),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/mortgage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('주택 담보 대출 한도 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>주택 담보 대출 한도 계산기 - 모두의 도구</title>
        <meta name="description" content="주택 가치와 LTV 비율을 기준으로 대출 한도와 월 상환액을 계산하는 주택 담보 대출 한도 계산기입니다." />
        <meta property="og:title" content="주택 담보 대출 한도 계산기 - 모두의 도구" />
        <meta property="og:description" content="주택 담보 대출 한도를 계산하여 예상 월 상환액을 확인해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        주택 담보 대출 한도 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="주택 가치 (원)"
          variant="outlined"
          value={propertyValue}
          onChange={(e) => setPropertyValue(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="LTV 비율 (%)"
          variant="outlined"
          value={ltvRatio}
          onChange={(e) => setLtvRatio(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="예상 이자율 (%)"
          variant="outlined"
          value={expectedInterestRate}
          onChange={(e) => setExpectedInterestRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="대출 기간 (년)"
          variant="outlined"
          value={loanTermYears}
          onChange={(e) => setLoanTermYears(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateMortgage}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="주택 가치" secondary={`${result.property_value.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="LTV 비율" secondary={`${result.ltv_ratio} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="대출 한도" secondary={`${result.loan_limit.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="예상 이자율" secondary={`${result.expected_interest_rate} %`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="대출 기간" secondary={`${result.loan_term_years} 년`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="월 상환액" secondary={`${result.monthly_payment.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default MortgageCalculator;
