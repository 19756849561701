import React from 'react';
import { Grid, Paper, Typography, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import toolsData from './toolsData';

const Tools = toolsData.find(category => category.category === '스마트스토어')?.tools || [];

export default function SmartstoreMain() {
  return (
    <Container maxWidth="lg" sx={{ pt: 5 }}>
      <Typography variant="h4" component="h2" sx={{ mb: 3, fontWeight: 'bold' }}>
        스마트스토어
      </Typography>
      <Grid container spacing={4}>
        {Tools.map((tool, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Link to={tool.link} style={{ textDecoration: 'none', flexGrow: 1 }}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  backgroundColor: 'white',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    backgroundColor: '#e8eaf6',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ mr: 2 }}>{tool.icon}</Box>
                  <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
                    {tool.title}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {tool.description}
                </Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
