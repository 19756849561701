import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Button, TextField, Alert, CircularProgress } from '@mui/material';

function OfficeConvertDocxToHwp() {
  const [docxFile, setDocxFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const handleFileChange = (event) => {
    setDocxFile(event.target.files[0]);
    setErrorMessage(null); // 새로운 파일 선택 시 에러 메시지 초기화
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!docxFile) {
      setErrorMessage('DOCX 파일을 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('file', docxFile);

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/tool/office/convert-docx-to-hwp`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('DOCX 파일 변환 중 오류가 발생했습니다.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'converted_file.hwp';
      document.body.appendChild(a);
      a.click();
      a.remove();

      setDocxFile(null); // 파일 선택 초기화
      setErrorMessage(null); // 에러 메시지 초기화
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>DOCX를 HWP로 변환 - 모두의 도구</title>
        <meta name="description" content="DOCX 파일을 HWP 파일로 변환하여 다운로드할 수 있는 도구입니다." />
        <meta property="og:title" content="DOCX를 HWP로 변환 - 모두의 도구" />
        <meta property="og:description" content="DOCX 파일을 업로드하여 HWP 파일로 변환해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        DOCX를 HWP로 변환
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          type="file"
          fullWidth
          inputProps={{ accept: '.docx' }}
          onChange={handleFileChange}
          variant="outlined"
          required
          sx={{ mb: 3 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? <CircularProgress size={24} /> : '변환 및 다운로드'}
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
}

export default OfficeConvertDocxToHwp;
